import { useNuxtApp } from '#app';

const useSanctumAuth = (url?: string) => {
    const { $sanctumAuth } = useNuxtApp()
    const router = useRouter()
    const route = useRoute()

    async function login(fields: StringKeyJson) {
        try {
            await $sanctumAuth.login(
                fields,
                (data) => {
                    // read route where user was redirected from and redirect back to it if exists
                    if (route.redirectedFrom?.fullPath) {
                        router.push(route.redirectedFrom.fullPath)
                    } else {
                        router.push(AppUrl.PAGE_DASHBOARD)
                    }

                },
            )
        } catch (err: any) {
            // errors.value = [e.message]
            console.error(err.message)
            throw err
        }
    }

    async function logout() {
        try {
            await $sanctumAuth.logout(
                (data) => {
                }
            )
        } catch (e) {
        }
        let sessionCookie = useCookie('vilor_referral_session')
        sessionCookie.value = null
        router.push(AppUrl.PAGE_LOGIN)
        reloadNuxtApp()
    }

    return {
        login,
        logout
    }
}

export default useSanctumAuth;
